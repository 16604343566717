import cloneDeep from 'lodash/cloneDeep';

/**
 * Очистка настройки, поля формы. Заменяет techId и опционально удаляет id.
 * @param element {object} - очищаемый объект
 * @param shouldRemoveId {boolean} - нужно ли удалять id?
 * @param techId {number|string} - новый techId
 * @returns {object} - очищенный объект
 */
const getClearElement = (
  element,
  shouldRemoveId = true,
  techId = window.HM_CURRENT_UNIQID(),
) => {
  const copy = {
    ...cloneDeep(element),
    techId,
  };

  if (shouldRemoveId) {
    delete copy.id;
  }

  return copy;
};

const copyElement = (element) => {
  if (!element) return;

  const newEl = getClearElement(element);

  if (newEl.classType === 'group') {
    Object.assign(newEl, { groupId: newEl.techId });
  }

  // очистка всех настроек от id и проброс новых techId
  if (newEl.settings?.length) {
    newEl.settings = newEl.settings.map((setting) => getClearElement(setting, false));
  }

  /**
   * Для чекбокс-группы, радио-группы и селектов нужно очищать элементы настройки "values".
   * То есть у каждого элемента чекбокс-группы, радио-группы и селекта должен быть удален
   * id, выставлен новый techId, и очищать connectedGroups
   */
  if (['select', 'radioGroup', 'checkboxGroup'].includes(newEl.classType)) {
    const valuesSettingIndex = newEl.settings.findIndex((value) => value.techName === 'values');

    if (valuesSettingIndex < 0) return;

    newEl.settings[valuesSettingIndex].values = newEl.settings[valuesSettingIndex].values
      .map((value) => ({
        ...getClearElement(value),
        connectedGroups: [],
      }));
  }

  if (newEl.childs?.length) {
    // eslint-disable-next-line no-param-reassign
    newEl.childs = cloneDeep(newEl.childs).map((item) => copyElement(item));
  }

  // eslint-disable-next-line consistent-return
  return newEl;
};

export default copyElement;
