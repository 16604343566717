<template>
  <div :class="className">
    <p v-if="isSearching">
      Просмотр вложенных элементов недоступен при поиске
    </p>
    <draggable
      v-else
      :class="{ 'no-empty': elementsList.length > 0, 'list-group': true }"
      group="controls"
      :list="elementsList"
      @input="(data) => elementsList = data"
    >
      <transition-group type="transition" name="flip-list">
        <template
          v-for="(el, index) in elementsList"
        >
          <control-item
            :key="el.techId"
            :element="el"
            :is-dark="isDark"
            @on-remove="removeControl"
            @on-copy="onCopy(el, index)"
            @on-update="update"
          />
        </template>
      </transition-group>
    </draggable>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import draggable from 'vuedraggable';
import cloneDeep from 'lodash/cloneDeep';
import copyElement from '@/components/service-form/helpers/copyElement';
import Hub from '@/plugins/event-hub';

const ControlItem = () => import('@/components/service-form/ControlItem.vue');

const prohibitedControls = {
  table: 'Таблица',
  group: 'Группа',
  employeeReplacement: 'Переезд',
  placesAndContracts: 'Площадки и договоры',
  resourceField: 'Ресурсное поле',
};

export default {
  name: 'TableAndGroupView',
  inject: ['globalState'],
  model: {
    prop: 'elements',
    event: 'on-update',
  },
  components: { ControlItem, draggable },
  props: {
    techId: {
      type: Number,
      default: undefined,
    },
    settings: {
      type: Array,
      default: () => [],
    },
    elements: {
      type: Array,
      default: () => [],
    },
    isDark: {
      type: Boolean,
    },
    isTable: {
      type: Boolean,
    },
  },
  computed: {
    ...mapState('controlSearch', ['isSearching']),
    className() {
      return this.isTable ? 'table-view' : 'group-view';
    },
    elementsList: {
      get() {
        return this.elements;
      },
      set(val) {
        this.$emit('on-update', val);
      },
    },
  },
  methods: {
    removeControl(techId) {
      const index = this.elementsList.findIndex((parent) => parent.techId === techId);
      if (index >= 0) {
        this.elementsList.splice(index, 1);
      }
    },
    onCopy(el, index) {
      const copy = copyElement(cloneDeep(el));

      this.elementsList.splice(index + 1, 0, copy);
    },
    update({ id, data }) {
      const index = this.elementsList.findIndex((parent) => parent.techId === id);
      if (index >= 0) {
        this.$set(this.elementsList, index, data);
      }
    },
    updateElementSettings(el) {
      const index = this.elementsList.findIndex((parent) => parent.techId === el.techId);
      if (index >= 0) {
        this.$set(this.elementsList[index], 'settings', el.settings);
      }
    },
  },
  mounted() {
    Hub.$on('update-element-settings', this.updateElementSettings);
  },
  beforeDestroy() {
    Hub.$off('update-element-settings', this.updateElementSettings);
  },
  watch: {
    elements(val) {
      if (this.isTable && val?.length) {
        const prohibited = val.find((el) => Object.keys(prohibitedControls).includes(el.classType));
        if (prohibited) {
          this.removeControl(prohibited.techId);
          this.$EsmpNotify.$show(
            `Добавлять компонент «${prohibitedControls[prohibited.classType]}» в таблицу запрещено`,
            'error',
          );
        }
      }
    },
  },
};
</script>
<style lang="scss">
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.table-view, .group-view {
  .list-group {
    min-height: 50px;
    > span {
      display: block;
      min-height: 50px;
      padding: 20px;
    }
  }
}
</style>
